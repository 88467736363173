import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import AppPromoBanner from '@/components/Auth/PromoBanner';
export default {
  components: {
    'app-promo-banner': AppPromoBanner
  },
  props: ['isShowForm'],
  computed: {
    data: function data() {
      return this.$store.getters['modals/checkEmailModal'].data;
    },
    typeMail: function typeMail() {
      var letters = /^[A-Za-z]+$/;
      if (this.data.nickName.includes('@') || letters.test(this.data.nickName)) return true;
      return false;
    },
    loginPromo: function loginPromo() {
      return this.$store.getters['auth/loginPromo'];
    }
  },
  methods: {
    openAuthModal: function openAuthModal(value) {
      this.$store.dispatch('modals/setCheckEmailModal', {
        isOpen: false
      });
      this.$store.dispatch('modals/setAuthModal', {
        isOpen: true,
        activeTab: value
      });
    }
  },
  mounted: function mounted() {
    this.$store.gaRegister.forgotPassword({
      event: 'forgot_password',
      location: 'login',
      step: 'success'
    });
  }
};